body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body,
#root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

main {
  flex: 1 0 auto;
}

:focus {
  outline: none !important;
}
::-moz-focus-inner {
  border: 0;
  outline: none !important;
}

:root {
  --color-sdg-blue: #006cb7;
  --color-blue-background: #dde9f5;
  --color-main-background: #f2f2f2;
  --color-white: #fff;
  --color-un-blue: #5090cd;
  --color-grey: #c6c8ca;
  --color-black: #333;
  --color-bos: #ad6500;
  --color-cfr: #07642b;
  --color-ims: #005e80;
  --color-cpr: #5a4f96;
  --color-cbo: #972086;
  --color-alert: #a71d44;
  --color-warning: #173d57;
}

.alert {
  color: var(--color-alert);
}

/* Form.io styles. 
 * This is a bit of a hack. Ideally we could do this properly
 * but we'll have to implement a Form.io template for this:
 * https://github.com/formio/react-formio/issues/272
 */

.save-buttons {
  align-self: center;
}

.btn.btn-primary {
  background-color: var(--color-white);
  color: var(--color-sdg-blue);
  border: 1px solid var(--color-sdg-blue);
  border-radius: 2rem;
  padding: 8px 24px;
  font-size: 0.875rem;
  min-width: 64px;
  margin-top: 10px;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-weight: 500;
  line-height: 1.75;
  white-space: nowrap;
}

.btn.btn-danger {
  background-color: var(--color-white);
  color: var(--color-alert);
  border: 1px solid var(--color-alert);
  border-radius: 2rem;
  padding: 8px 24px;
  font-size: 0.875rem;
  min-width: 64px;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-weight: 500;
  line-height: 1.75;
}

.btn.btn-primary:hover {
  color: #006cb7;
  border: 1px solid #006cb7;
  background-color: rgba(0, 108, 183, 0.04);
}

.card-title {
  font-weight: 700;
}

/* A fix for handsontable: https://github.com/handsontable/handsontable/issues/3815 */
body > span {
  position: absolute;
}
.handsontable .htDimmed {
  background: #e9f0f3;
}
.handsontable .grey {
  background-color: #f0f0f0;
}

.handsontable th {
  white-space: normal !important;
}
